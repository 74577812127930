import dayjs from 'dayjs';
import type { ExpirationTime, ExpirationTimeList } from '../../types';

const SHORT_TIMES_LENGTH = 5;
const SHORT_INTERVAL = 1;

const getCurrent = () => {
	let initial = dayjs(new Date()).set('seconds', 0).set('milliseconds', 0);

	if (isNeedNextMinute()) {
		initial = initial.add(1, 'minute');
	}

	return initial;
};

export const isNeedNextMinute = () => dayjs(new Date()).get('seconds') >= 30;

export const getInitialExpirationTime = (): ExpirationTime => {
	const initial = getCurrent();
	let next = initial.add(SHORT_INTERVAL, 'minute');

	if (isNeedNextMinute()) {
		next = next.add(1, 'minute');
	}

	return {
		value: next.toDate(),
		stringified: next.format('HH:mm'),
	};
};

export const generateExpirationTimeShortList = (): ExpirationTimeList => {
	const initial = getCurrent();
	return new Array(SHORT_TIMES_LENGTH)
		.fill(null)
		.map((_, idx) => initial.add(idx + SHORT_INTERVAL, 'minute'))
		.map(date => ({
			value: date.toDate(),
			stringified: date.format('HH:mm'),
		}));
};

const LONG_TIMES_LENGTH = 20;
const LONG_INTERVAL = 15;

export const generateExpirationTimeLongList = (): ExpirationTimeList => {
	const initial = getCurrent();
	return new Array(LONG_TIMES_LENGTH)
		.fill(null)
		.map((_, idx) => initial.add((idx + 1) * LONG_INTERVAL, 'minute'))
		.map(date => ({
			value: date.toDate(),
			stringified: date.format('HH:mm'),
		}));
};

const FIRST_VALUE = 1;
const PROGRESSION_DIFFERENCE = 50;
const PROGRESSION_LENGTH = 21;

export const generateExpirationTimeList = () => {
	return [
		FIRST_VALUE,
		...new Array(PROGRESSION_LENGTH)
			.fill(null)
			.map((_, idx) => idx * PROGRESSION_DIFFERENCE)
			.slice(1),
	];
};

export const getNextExpirationTime = (
	current: ExpirationTime,
	list: ExpirationTimeList,
) => {
	const sorted = list.sort((a, b) => a.value.getTime() - b.value.getTime());
	const idx = sorted.findIndex(
		item => item.value.getTime() > current.value.getTime(),
	);

	if (idx !== -1 && idx + 1 < sorted.length) {
		return sorted[idx];
	}
	else if (idx === sorted.length - 1) {
		return current;
	}
	else {
		return current;
	}
};

export const getPrevExpirationTime = (
	current: ExpirationTime,
	list: ExpirationTimeList,
) => {
	const sorted = list.sort((a, b) => b.value.getTime() - a.value.getTime());
	const idx = sorted.findIndex(
		item => item.value.getTime() < current.value.getTime(),
	);

	if (idx > 0) {
		return sorted[idx];
	}
	else {
		return sorted[sorted.length - 1];
	}
};

export const getExpirationTimeFromStringifyed = (
	stringified: string,
): ExpirationTime => {
	const [hours, minutes] = stringified.split(':');
	const date = getCurrent()
		.set('hours', +hours)
		.set('minutes', +minutes)
		.toDate();

	return {
		value: date,
		stringified,
	};
};

export const getNearestExpirationTime = (
	expirationTime: ExpirationTime,
	list: ExpirationTimeList,
): ExpirationTime | null => {
	if (list[0].value.getTime() > expirationTime.value.getTime()) {
		return list[0];
	}
	else {
		return null;
	}
};
