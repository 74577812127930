import type { BaseIconName } from '@legacy-studio/library';
import type { List } from './types';
import {
	useIsAdminLayout,
	useIsPartnerLayout,
	useIsTraderLayout,
} from './core/application';
import { useGetTariffs } from '~/src/features/tariffs';
import { useRoutes } from '~/src/shared/lib';
import { useRootUserUrls } from '~/src/features/user';

export const usePartners = () => {
	const routes = useRoutes();

	return computed(() => ({
		list: [
			{
				title: 'Статистика',
				link: routes.value.partnersStatistics,
				icon: 'base--fill--chart-square-bar',
			},
			{
				title: 'Промо',
				link: routes.value.partnersPromo,
				icon: 'base--fill--cursor-click',
			},
			{
				title: 'Рефераллы',
				link: routes.value.partnersReferals,
				icon: 'base--fill--users',
			},
			{
				title: 'Выплаты',
				link: routes.value.partnersPayments,
				icon: 'base--fill--credit-card',
			},
			{
				title: 'Турниры',
				link: routes.value.partnersTournaments,
				icon: 'base--fill--cup',
			},
			{
				title: 'Новости',
				link: routes.value.partnersNews,
				icon: 'base--fill--newspaper',
			},
			{
				title: 'Тарифы',
				link: routes.value.partnersTariffs,
				icon: 'base--fill--diamond',
			},
			{
				title: 'Поддержка',
				link: routes.value.partnersSupport,
				icon: 'base--fill--chat',
			},
			{
				title: 'FAQ',
				link: routes.value.partnersFaq,
				icon: 'base--fill--academic-cap',
			},
			{
				title: 'Контакты',
				link: routes.value.partnersContacts,
				icon: 'base--fill--information-circle',
			},
		] as List,
	}));
};

export const useTrader = () => {
	const routes = useRoutes();

	return computed(() => ({
		list: [
			{
				title: 'Сделки',
				link: routes.value.terminalDeals,
				icon: 'base--fill--clock',
			},
			{
				title: 'Финансы',
				link: routes.value.terminalFinances,
				icon: 'base--fill--credit-card',
			},
			{
				title: 'Турниры',
				link: routes.value.terminalTournaments,
				icon: 'base--fill--cup',
			},
			{
				title: 'Обучение',
				link: routes.value.terminalEducation,
				icon: 'base--fill--academic-cap',
			},
			{
				title: 'Бонусы',
				link: routes.value.terminalBonuses,
				icon: 'base--fill--receipt-tax',
			},
		] as List,

		additional: [
			{
				title: 'Помощь',
				link: routes.value.terminalHelp,
				icon: 'base--fill--question-mark-circle',
			},
		] as List,
	}));
};

export const useAdmin = () => {
	const routes = useRoutes();

	return computed(() => ({
		list: [
			{
				title: 'Статистика',
				link: routes.value.adminStatistics,
				icon: 'base--fill--chart-square-bar',
			},
			{
				title: 'Пользователи',
				link: routes.value.adminUsers,
				icon: 'base--fill--users',
			},
			{
				title: 'Верификация',
				link: routes.value.adminVerification,
				icon: 'base--fill--check-circle',
			},
			{
				title: 'Дерево ставок',
				link: routes.value.adminBetsTree,
				icon: 'base--fill--rates',
			},
			{
				title: 'Бухгалтерия',
				link: routes.value.adminAccounting,
				icon: 'base--fill--chart-pie',
			},
			{
				title: 'Турниры',
				link: routes.value.adminTournaments,
				icon: 'base--fill--cup',
			},
			{
				title: 'Промокоды',
				link: routes.value.adminPromoCodes,
				icon: 'base--fill--receipt-tax',
			},
			{
				title: 'Поддержка',
				link: routes.value.adminSupport,
				icon: 'base--fill--chat',
			},
			{
				title: 'Обучение',
				link: routes.value.adminEducation,
				icon: 'base--fill--academic-cap',
			},
			{
				title: 'Акции',
				link: routes.value.adminPromotions,
				icon: 'base--fill--chat',
			},
		] as List,

		sub: [
			{
				title: 'Статистика',
				link: routes.value.adminPartnersStatistics,
				icon: 'base--fill--presentation-chart-bar',
			},
			{
				title: 'Партнеры',
				link: routes.value.adminPartners,
				icon: 'base--fill--users',
			},
			{
				title: 'Выплаты',
				link: routes.value.adminPartnersPayments,
				icon: 'base--fill--credit-card',
			},
			{
				title: 'Поддержка',
				link: routes.value.adminPartnersSupport,
				icon: 'base--fill--chat',
			},
			{
				title: 'Турниры',
				link: routes.value.adminPartnersTournaments,
				icon: 'base--fill--cup',
			},
			{
				title: 'Промо',
				link: routes.value.adminPartnersPromo,
				icon: 'base--fill--cursor-click',
			},
			{
				title: 'Новости',
				link: routes.value.adminPartnersNews,
				icon: 'base--fill--globe',
			},
			{
				title: 'FAQ',
				link: routes.value.adminPartnersFaq,
				icon: 'base--fill--academic-cap',
			},
		] as List,
	}));
};

export const useAppAsyncData = () => {
	useLazyAsyncData(useGetTariffs());
};

export const useScopes = () => {
	const isAdminLayout = useIsAdminLayout();
	const isPartnerLayout = useIsPartnerLayout();
	const isTraderLayout = useIsTraderLayout();
	const rootUserUrls = useRootUserUrls();
	const router = useRouter();

	return computed<
		Array<{
			title: string;
			active: boolean;
			icon: BaseIconName;
			callback: () => void;
		}>
	>(() => {
					return [
						{
							title: 'Платформа',
							active: isTraderLayout.value,
							icon: 'base--fill--rates',
							callback: () => router.push(rootUserUrls.value.trader),
						},
						{
							title: 'Партнерка',
							active: isPartnerLayout.value,
							icon: 'base--fill--user-group',
							callback: () => router.push(rootUserUrls.value.partner),
						},
						{
							title: 'Админка',
							active: isAdminLayout.value,
							icon: 'base--fill--cog',
							callback: () => router.push(rootUserUrls.value.admin),
						},
					];
				});
};
