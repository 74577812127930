import type { CreateDealData } from '../../core/types';
import { createEventHook } from '~/src/shared/lib';

type TermianlCreateDeal = (data: CreateDealData) => void;

const HOOK_NAME = 'termianl:create-deal';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[HOOK_NAME]: TermianlCreateDeal;
	}
}

const {
	useOnEvent: useSubscribeTermianlCreateDeal,
	useTriggerEvent: useTriggerTermianlCreateDeal,
} = createEventHook(HOOK_NAME);

export const terminalBus = {
	useSubscribeTermianlCreateDeal,
	useTriggerTermianlCreateDeal,
};
