import { createGlobalState } from '@vueuse/core';
import type { PromocodeInfo } from '../../core/types';
import type { PaymentMethodItem } from '~/src/features/payment-method';
import type { SumData } from '~/src/features/sum';

const useStore = createGlobalState(() => {
	const paymentMethod = ref<PaymentMethodItem | null>(null);
	const sumData = ref<SumData | null>(null);
	const promocodeInfo = ref<PromocodeInfo | null>(null);

	return {
		paymentMethod,
		sumData,
		promocodeInfo,
	};
});

export const usePaymentMethod = () => {
	const { paymentMethod } = useStore();
	return computed(() => paymentMethod.value);
};

export const useSumData = () => {
	const { sumData } = useStore();
	return computed(() => sumData.value);
};

export const usePromocodeInfo = () => {
	const { promocodeInfo } = useStore();
	return computed(() => promocodeInfo.value);
};

export const savePaymentMethod = (method: PaymentMethodItem) => {
	useStore().paymentMethod.value = method;
};

export const saveSumData = (sumData: SumData) => {
	useStore().sumData.value = sumData;
};

export const savePromocodeInfo = (promocode: PromocodeInfo | null) => {
	useStore().promocodeInfo.value = promocode;
};

export const readPaymentMethod = () => {
	return useStore().paymentMethod.value;
};

export const readSumData = () => {
	return useStore().sumData.value;
};

export const readPromocodeInfo = () => {
	return useStore().promocodeInfo.value;
};
