import { createGlobalState } from '@vueuse/core';
import type { LoginTab, RegistrationTab } from '../../types';
import { LOGIN_TAB, REGISTRATION_TAB } from '../../constants';

const useStore = createGlobalState(() => {
	const loginTab = ref<LoginTab>(LOGIN_TAB.login);
	const registrationTab = ref<RegistrationTab>(REGISTRATION_TAB.sendCode);

	return {
		loginTab,
		registrationTab,
	};
});

export const saveLoginTab = (formType: LoginTab) => {
	const store = useStore();
	store.loginTab.value = formType;
};

export const useLoginTab = () => {
	const store = useStore();
	return computed(() => store.loginTab.value);
};

export const saveRegistrationTab = (formType: RegistrationTab) => {
	const store = useStore();
	store.registrationTab.value = formType;
};

export const useRegistrationTab = () => {
	const store = useStore();
	return computed(() => store.registrationTab.value);
};
