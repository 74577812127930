import type { PaymentMethodItem } from '../..';
import { createEventHook } from '~/src/shared/lib';

type PaymentMethodSelected = (item: PaymentMethodItem) => void;

const HOOK_NAME = 'payment-method:selected';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[HOOK_NAME]: PaymentMethodSelected;
	}
}

const {
	useOnEvent: useSubscribePaymentMethodSelected,
	useTriggerEvent: useTriggerPaymentMethodSelected,
} = createEventHook(HOOK_NAME);

export const paymentMethodBus = {
	useSubscribePaymentMethodSelected,
	useTriggerPaymentMethodSelected,
};
