<template>
	<span
		class="ui-icon h-[1em] w-[1em]"
		:class="{
			'ui-icon--fill': fill || full,
			'ui-icon--stroke': stroke || full,
		}"
		v-html="icon"
	/>
</template>

<script setup lang="ts">
import type { UiIconName } from './Icon';

export interface Icon {
	name: UiIconName;
}

const props = defineProps<Icon>();

const type = computed(() => {
	if (props.name.startsWith('filled')) {
		return 'filled';
	}
	else if (props.name.startsWith('stroke')) {
		return 'stroke';
	}
	else if (props.name.startsWith('fill')) {
		return 'fill';
	}
	else {
		return 'full';
	}
});

const stroke = computed(() => type.value === 'stroke');
const fill = computed(() => type.value === 'fill');
const full = computed(() => type.value === 'full');

const icon = ref<string | Record<string, any>>('');

async function getIcon() {
	try {
		if (!props.name) {
			return;
		}

		const iconsImport = import.meta.glob('/src/shared/assets/icons/**/**.svg', {
			as: 'raw',
			eager: false,
		});

		const iconName = props.name.replaceAll('--', '/');

		icon.value
      = await iconsImport[`/src/shared/assets/icons/${iconName}.svg`]();
	}
	catch {
		console.error(`Icon '${props.name}' doesn't exist`);
	}
}

getIcon();
watchEffect(getIcon);
</script>

<style>
.ui-icon svg {
  width: inherit;
  height: inherit;
}

.ui-icon.ui-icon--fill,
.ui-icon.ui-icon--fill * {
  fill: currentColor !important;
  stroke: none !important;
}

.ui-icon.ui-icon--stroke,
.ui-icon.ui-icon--stroke * {
  stroke: currentColor !important;
  fill: none !important;
}
</style>
