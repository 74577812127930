import { createGlobalState } from '@vueuse/core';
import type {
	ClosedDealsPageData,
	Deals,
	DealsSection,
} from '../../core/types';

const useStore = createGlobalState(() => {
	const closedDeals = ref<ClosedDealsPageData | null>(null);
	const closedDealsPage = ref<number>(1);
	const activeDeals = ref<Deals>([]);
	const openedDealsSection = ref<DealsSection | null>(null);

	return {
		closedDeals,
		closedDealsPage,
		activeDeals,
		openedDealsSection,
	};
});

export const saveClosedDeals = (deals: ClosedDealsPageData | null) =>
	(useStore().closedDeals.value = deals);
export const saveClosedDealsPage = (page: number) =>
	(useStore().closedDealsPage.value = page);
export const saveActiveDeals = (deals: Deals) =>
	(useStore().activeDeals.value = deals);
export const saveNewActiveDeal = (deal: Deals[0]) =>
	(useStore().activeDeals.value = [...useStore().activeDeals.value, deal]);
export const saveOpenedDealsSection = (section: DealsSection | null) =>
	(useStore().openedDealsSection.value = section);

export const readClosedDealsPage = () => useStore().closedDealsPage.value;
export const readClosedDealsCount = () => useStore().closedDeals.value?.count;
export const readClosedDealsItems = () => useStore().closedDeals.value?.items;
export const readActiveDeals = () => useStore().activeDeals.value;

export const useClosedDeals = () => {
	const { closedDeals } = useStore();
	return computed(() => closedDeals.value?.items || []);
};
export const useActiveDeals = () => {
	const { activeDeals } = useStore();
	return computed(() => activeDeals.value);
};
export const useOpenedDealsSection = () => {
	const { openedDealsSection } = useStore();
	return computed(() => openedDealsSection.value);
};
export const useOpenedDealsSectionName = () => {
	const { openedDealsSection } = useStore();
	return computed(() => openedDealsSection.value?.asset || '');
};
