<script setup lang="ts">
import { BaseIcon, type BaseIconName } from '@legacy-studio/library';

interface Props {
	size?: 'xs' | 'sm' | 'base' | 'l' | 'xl';
	buttonText?: string;
	outline?: boolean;
	iconOnly?: boolean;
	iconStyle?: BaseIconName;
	leftIconStyle?: BaseIconName;
	rightIconStyle?: BaseIconName;
	tag?: string;
	filledIcon?: boolean;
	type?: string;
	disabled?: boolean;
}

interface Slots {
	'default'?: any;
	'left'?: any;
	'right'?: any;
	'icon-only'?: any;
}

const props = withDefaults(defineProps<Props>(), {
	buttonText: '',
	outline: false,
	iconOnly: false,
	iconStyle: undefined,
	leftIconStyle: undefined,
	rightIconStyle: undefined,
	tag: 'button',
	type: 'button',
	disabled: false,
	size: 'base',
});

defineSlots<Slots>();

const classes = computed(() => ({
	'flex items-center justify-center gap-2 font-medium outline-none cursor-pointer':
    true,
	'rounded-lg': !props.iconOnly,
	'rounded-full': props.iconOnly,
	'h-[28px] w-[28px]': props.size === 'xs' && props.iconOnly,
	'h-[36px] w-[36px]': props.size === 'sm' && props.iconOnly,
	'h-[40px] w-[40px]': props.size === 'base' && props.iconOnly,
	'h-[48px] w-[48px]': props.size === 'l' && props.iconOnly,
	'h-[52px] w-[52px]': props.size === 'xl' && props.iconOnly,
	'text-xs h-[34px] px-3': props.size === 'xs' && !props.iconOnly,
	'text-sm h-[37px] px-3': props.size === 'sm' && !props.iconOnly,
	'text-sm h-[41px] px-5': props.size === 'base' && !props.iconOnly,
	'text-base h-[48px] px-5': props.size === 'l' && !props.iconOnly,
	'text-base h-[52px] px-6': props.size === 'xl' && !props.iconOnly,
	'!w-[34px] !h-[34px]': props.outline && props.iconOnly && props.size === 'xs',
	'!rounded-lg': props.outline && props.iconOnly,
	'focus:ring-2': props.iconOnly && props.size === 'xs',
	'focus:ring': !(props.iconOnly && props.size === 'xs'),
	'bg-primary-300 text-black shadow-bb-500 hocus:shadow-none hocus:bg-primary-200 hocus:ring-primary-200':
    !props.outline,
	'border border-1 text-gray-400 border-gray-600 bg-gray-700 text-white hover:bg-gray-600 focus:border-gray-700 focus:bg-gray-700 focus:ring-gray-600':
    props.outline,
}));

const iconClasses = computed(() => ({
	'text-[16px]': ['xs', 'sm'].includes(props.size) && !props.iconOnly,
	'text-[20px]':
    (!['xs', 'sm'].includes(props.size) && !props.iconOnly)
    || (['xs', 'sm', 'base'].includes(props.size) && props.iconOnly),
	'text-[24px]': !['xs', 'sm', 'base'].includes(props.size) && props.iconOnly,
}));
</script>

<template>
	<component
		:is="tag"
		:class="classes"
		:type="type"
		:disabled="disabled"
	>
		<template v-if="iconOnly">
			<slot name="icon-only">
				<BaseIcon
					v-if="iconStyle"
					:name="iconStyle"
					:class="iconClasses"
					:filled="filledIcon"
				/>
			</slot>
		</template>

		<template v-else>
			<slot name="left">
				<BaseIcon
					v-if="leftIconStyle"
					:name="leftIconStyle"
					:class="iconClasses"
					:filled="filledIcon"
				/>
			</slot>

			<slot name="default">
				<span>
					{{ buttonText }}
				</span>
			</slot>

			<slot name="right">
				<BaseIcon
					v-if="rightIconStyle"
					:name="rightIconStyle"
					:class="iconClasses"
					:filled="filledIcon"
				/>
			</slot>
		</template>
	</component>
</template>
