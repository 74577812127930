import { createGlobalState } from '@vueuse/core';
import type { Invoice, Invoices } from '../../types';

const useStore = createGlobalState(() => {
	const invoices = ref<Invoices>([]);
	const activeInvoice = ref<Invoice | null>(null);

	return {
		invoices,
		activeInvoice,
	};
});

export const useInvoices = () => {
	const { invoices } = useStore();
	return computed(() => invoices.value);
};

export const useActiveInvoice = () => {
	const { activeInvoice } = useStore();
	return computed(() => activeInvoice.value);
};

export const saveInvoices = (invoices: Invoices) => {
	useStore().invoices.value = invoices;
};

export const saveActiveInvoice = (activeInvoice: Invoice | null) => {
	useStore().activeInvoice.value = activeInvoice;
};

export const readInvoices = () => {
	return useStore().invoices.value;
};

export const readActiveInvoice = () => {
	return useStore().activeInvoice.value;
};
