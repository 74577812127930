import type { UserCurrent } from '../../types';
import { useApi } from '@/src/services/network';
import { USER_ACCOUNT_TYPE, USER_ROLES } from '~/src/shared/lib';
import type {
	Response,
	UserAccountType,
	UserColorCode,
	UserGroupId,
	UserId,
} from '~/src/shared/lib';

export type UserDTO = Response<{
	id: UserId;
	referal_code: null | string;
	login: string;
	email: string;
	name: string;
	last_name: string;
	photo: string;
	phone_number: string;
	group: [
		{
			id: string;
			name: string;
			code: UserGroupId;
		},
	];
	color: UserColorCode | null;
	date_register: DateString;
	language: string;
	country: null | string;
	tariff: {
		id: string;
		name: string;
		code: UserAccountType;
	};
}>;

export const toDomain = (user: UserDTO): UserCurrent => ({
	id: user.result.id,
	login: user.result.login,
	email: user.result.email,
	firstName: user.result.name,
	lastName: user.result.last_name,
	photo: user.result.photo,
	phone: user.result.phone_number,
	colorCode: user.result.color || '1',
	accountType: user.result.tariff?.code ?? USER_ACCOUNT_TYPE.free,
	groupId: user.result.group?.[0]?.code ?? USER_ROLES.trader,
	language: user.result.language,
	registrationDate: user.result.date_register,
	refferalCode: user.result.referal_code,
	country: user.result.country,
});

export const useGetUser = () => {
	const api = useApi();

	return async () => (await api<UserDTO>('/User/get/')).mapRight(toDomain);
};
