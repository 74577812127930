<script setup lang="ts">
import {
	BaseModal,
	BaseModalCard,
	BaseModalCardHeader,
	BaseModalCardContent,
} from '@legacy-studio/library';
import { closeTariffsModal } from '../core/application';
import { useIsTariffsModalOpen } from '../infrastructure/store';
import TariffsList from './List.vue';
import { UiLogo } from '@/src/shared/ui';

const isOpen = useIsTariffsModalOpen();

const localModel = computed({
	get() {
		return isOpen.value;
	},

	set() {
		closeTariffsModal();
	},
});
</script>

<template>
	<BaseModal
		v-model="localModel"
		size="lg"
	>
		<BaseModalCard
			class="max-w-[355px] md:max-w-[480px] xl:max-w-[1344px] max-h-[500px]"
			hide-close
		>
			<BaseModalCardHeader with-close>
				<UiLogo
					size="sm"
					with-text
					text="Типы счетов"
				/>
			</BaseModalCardHeader>

			<BaseModalCardContent>
				<section class="flex flex-col gap-12">
					<p
						class="mx-auto text-base font-normal text-gray-400 md:text-lg xl:max-w-[768px]"
					>
						Тариф зависит от суммы всех внесенных депозитов. Срок действия
						присвоенного типа счета не ограничен
					</p>

					<TariffsList />
				</section>
			</BaseModalCardContent>
		</BaseModalCard>
	</BaseModal>
</template>
