import dayjs from 'dayjs';
import {
	LineType,
	type UTCTimestamp,
	type DeepPartial,
	type ChartOptions,
	ColorType,
	LineStyle,
	CrosshairMode,
} from 'lightweight-charts';
import { CHART_INTERVAL, CHART_PERIOD_TYPE, CHART_TYPE } from '../../constants';
import type {
	Chart,
	ChartPeriodType,
	ChartInterval,
	ChartRateItem,
	ChartSeries,
	ChartType,
} from '../../types';
import type { AssetItem, AssetsList } from '~/src/features/assets';
import type {
	ResolutionString,
	SeriesType,
} from '~/public/charting_library/charting_library';

export const setSeries = (chart: Chart, type: ChartType): ChartSeries => {
	if (type === CHART_TYPE.line) {
		return chart.addLineSeries({
			color: '#F2C770',
			lineType: LineType.Curved,
		});
	}
	else if (type === CHART_TYPE.bars) {
		return chart.addBarSeries({
			downColor: '#EB5757',
			upColor: '#27AE60',
		});
	}
	else if (type === CHART_TYPE.candlestick) {
		return chart.addCandlestickSeries({
			upColor: '#27AE60',
			downColor: '#EB5757',
			wickUpColor: '#27AE60',
			wickDownColor: '#EB5757',
		});
	}
	else {
		return chart.addAreaSeries({
			lineColor: '#F2C770',
			lineType: LineType.Curved,
		});
	}
};

export const getHistoryType = (interval: ChartInterval): ChartPeriodType => {
	return (
		{
			'1sec': 'seconds',
			'5sec': 'seconds',
			'15sec': 'seconds',
			'30sec': 'seconds',
			'1min': 'minutes',
			'5min': 'minutes',
			'15min': 'minutes',
			'30min': 'minutes',
			'1h': 'hours',
			'3h': 'hours',
			'1d': 'days',
			'30d': 'days',
		} as const
	)[interval];
};

export const createFetchedPeriod = (
	type: ChartPeriodType,
	leftTimestamp?: TimeStamp,
) => {
	const from = dayjs(leftTimestamp);

	const format = 'YYYY-MM-DD HH:mm:ss';

	let fromDate;
	if (type === 'hours') {
		fromDate = from.subtract(1, 'week').format(format);
	}
	else if (type === 'minutes' || type === 'seconds') {
		fromDate = from.subtract(2, 'days').format(format);
	}
	else {
		fromDate = from.subtract(11, 'month').format(format);
	}

	return [fromDate, from.format(format)] as [DateString, DateString];
};

export const getHistoryOptions = (
	activeAsset: AssetItem,
	period: [DateString, DateString],
) => ({
	asset: activeAsset.id,
	from: period[0],
	to: period[1],
});

export const createSocketMessage = (item: {
	currency: string;
	bid: number;
	ask: number;
	high: number;
	low: number;
	open: number;
	close: number;
	timestamp: number;
}) => {
	const dataMapItem: ChartRateItem = {
		time: item.timestamp as unknown as UTCTimestamp,
		open: item.open,
		high: item.high,
		low: item.low,
		close: item.close,
		value: item.ask,
	};

	if (
		typeof dataMapItem.open !== 'number'
		|| typeof dataMapItem.close !== 'number'
		|| typeof dataMapItem.value !== 'number'
	) {
		return null;
	}

	return dataMapItem;
};

const createGetIntervalParser
  = (value: number, periodType: ChartPeriodType) => (time: UTCTimestamp) => {
  	const multiplier
      = periodType === 'days'
      	? 24 * 60 * 60 * 1000
      	: periodType === 'hours'
      		? 60 * 60 * 1000
      		: periodType === 'minutes'
      			? 60 * 1000
      			: 1000;
  	const ms = multiplier * value;
  	return Math.floor(time / ms) * ms;
  };

export const getPeriodType = (interval: ChartInterval) => {
	return (
		{
			[CHART_INTERVAL['1sec']]: CHART_PERIOD_TYPE.seconds,
			[CHART_INTERVAL['5sec']]: CHART_PERIOD_TYPE.seconds,
			[CHART_INTERVAL['15sec']]: CHART_PERIOD_TYPE.seconds,
			[CHART_INTERVAL['30sec']]: CHART_PERIOD_TYPE.seconds,
			[CHART_INTERVAL['1min']]: CHART_PERIOD_TYPE.minutes,
			[CHART_INTERVAL['5min']]: CHART_PERIOD_TYPE.minutes,
			[CHART_INTERVAL['15min']]: CHART_PERIOD_TYPE.minutes,
			[CHART_INTERVAL['30min']]: CHART_PERIOD_TYPE.minutes,
			[CHART_INTERVAL['1h']]: CHART_PERIOD_TYPE.hours,
			[CHART_INTERVAL['3h']]: CHART_PERIOD_TYPE.hours,
			[CHART_INTERVAL['1d']]: CHART_PERIOD_TYPE.days,
			[CHART_INTERVAL['30d']]: CHART_PERIOD_TYPE.days,
		} as const
	)[interval];
};

const intervalMapper: Record<ChartInterval, (time: UTCTimestamp) => number> = {
	[CHART_INTERVAL['1sec']]: createGetIntervalParser(
		1,
		getPeriodType(CHART_INTERVAL['1sec']),
	),
	[CHART_INTERVAL['5sec']]: createGetIntervalParser(
		5,
		getPeriodType(CHART_INTERVAL['5sec']),
	),
	[CHART_INTERVAL['15sec']]: createGetIntervalParser(
		15,
		getPeriodType(CHART_INTERVAL['15sec']),
	),
	[CHART_INTERVAL['30sec']]: createGetIntervalParser(
		30,
		getPeriodType(CHART_INTERVAL['30sec']),
	),
	[CHART_INTERVAL['1min']]: createGetIntervalParser(
		1,
		getPeriodType(CHART_INTERVAL['1min']),
	),
	[CHART_INTERVAL['5min']]: createGetIntervalParser(
		5,
		getPeriodType(CHART_INTERVAL['5min']),
	),
	[CHART_INTERVAL['15min']]: createGetIntervalParser(
		15,
		getPeriodType(CHART_INTERVAL['15min']),
	),
	[CHART_INTERVAL['30min']]: createGetIntervalParser(
		30,
		getPeriodType(CHART_INTERVAL['30min']),
	),
	[CHART_INTERVAL['1h']]: createGetIntervalParser(
		1,
		getPeriodType(CHART_INTERVAL['1h']),
	),
	[CHART_INTERVAL['3h']]: createGetIntervalParser(
		3,
		getPeriodType(CHART_INTERVAL['3h']),
	),
	[CHART_INTERVAL['1d']]: createGetIntervalParser(
		1,
		getPeriodType(CHART_INTERVAL['1d']),
	),
	[CHART_INTERVAL['30d']]: createGetIntervalParser(
		30,
		getPeriodType(CHART_INTERVAL['30d']),
	),
};

export const createChartRatesItems = (
	interval: ChartInterval,
	items: Array<ChartRateItem>,
) => {
	const timeParser = intervalMapper[interval];

	const parsedItems = items
		.map((item) => {
			return {
				...item,
				time: timeParser(item.time),
			};
		})
		.sort((a, b) => a.time - b.time)
		.reduce(
			(acc, item) => {
				const key = item.time;
				acc[key as any] = item as any;
				return acc;
			},
			{} as Record<UTCTimestamp, ChartRateItem>,
		);

	return Object.values(parsedItems);
};

export const getAssetsList = (
	currentAssets: AssetsList,
	newAsset: AssetItem,
): AssetsList => {
	const alreadyHas = currentAssets.findIndex(
		asset => asset.id === newAsset.id,
	);

	if (alreadyHas === -1) {
		return [...currentAssets, newAsset];
	}
	else {
		return currentAssets;
	}
};

export const removeAssetFromList = (
	currentAssets: AssetsList,
	assetId: AssetItem['id'],
): AssetsList => {
	return currentAssets.filter(asset => asset.id !== assetId);
};

export const getActiveAsset = (currentAssets: AssetsList): AssetItem => {
	return currentAssets[0];
};

export const getSumWithPercent = (sum: number, percent: number) => {
	return sum + sum * (percent / 100);
};

export const getFormatByInterval = (interval: ChartInterval) => {
	const type = getHistoryType(interval);

	const format = (
		{
			seconds: 'YYYY-MM-DD HH:mm:ss',
			minutes: 'YYYY-MM-DD HH:mm',
			hours: 'YYYY-MM-DD HH',
			days: 'YYYY-MM-DD',
		} as const
	)[type];

	return format;
};

export const getChartOptions = (
	interval: ChartInterval,
): DeepPartial<ChartOptions> => ({
	layout: {
		textColor: '#6E6B80',
		fontSize: 14,
		background: { type: ColorType.Solid, color: '#161420' },
	},
	grid: {
		vertLines: { color: '#1F1D29', style: LineStyle.Solid },
		horzLines: { color: '#1F1D29', style: LineStyle.Solid },
	},
	crosshair: {
		mode: CrosshairMode.Normal,
		vertLine: {
			width: 1,
			color: '#F2C770',
			style: LineStyle.Dashed,
			labelBackgroundColor: '#F6D89B',
		},
		horzLine: {
			width: 1,
			color: '#F2C770',
			style: LineStyle.Dashed,
			labelBackgroundColor: '#F6D89B',
		},
	},
	localization: {
		priceFormatter: Intl.NumberFormat(window.navigator.languages[0], {
			style: 'currency',
			currency: 'USD',
			minimumFractionDigits: 5,
		}).format,
		timeFormatter: (time: UTCTimestamp) => {
			return dayjs(time).format(getFormatByInterval(interval));
		},
	},
	rightPriceScale: {
		ticksVisible: true,
	},
	timeScale: {
		tickMarkFormatter: (time: UTCTimestamp) => {
			return dayjs(time).format(getFormatByInterval(interval));
		},
	},
});

export const getWidgetResolution = (chartInterval: ChartInterval) =>
	({
		[CHART_INTERVAL['1sec']]: '1S',
		[CHART_INTERVAL['5sec']]: '5S',
		[CHART_INTERVAL['15sec']]: '15S',
		[CHART_INTERVAL['30sec']]: '30S',
		[CHART_INTERVAL['1min']]: '1',
		[CHART_INTERVAL['5min']]: '5',
		[CHART_INTERVAL['15min']]: '15',
		[CHART_INTERVAL['30min']]: '30',
		[CHART_INTERVAL['1h']]: '60',
		[CHART_INTERVAL['3h']]: '180',
		[CHART_INTERVAL['1d']]: '1D',
		[CHART_INTERVAL['30d']]: '30D',
	})[chartInterval] as ResolutionString;

export const getWidgetSerieType = (chartType: ChartType) =>
	({
		[CHART_TYPE.bars]: 0,
		[CHART_TYPE.candlestick]: 1,
		[CHART_TYPE.line]: 2,
		[CHART_TYPE.area]: 3,
	})[chartType] as SeriesType;
