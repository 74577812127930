import { createGlobalState } from '@vueuse/core';
import { generateDealSumList } from '../../core/domain';
import type { DealSum, DealSumList } from '../../types';

const items = generateDealSumList();

const useStore = createGlobalState(() => {
	const sum = ref<DealSum>(items[0]);
	const options = ref<DealSumList>(items);

	return {
		sum,
		options,
	};
});

export const useDealSum = () => {
	const { sum } = useStore();
	return computed(() => sum.value);
};

export const useDealSumList = () => {
	const { options } = useStore();
	return computed(() => options.value);
};

export const saveDealSum = (sum: DealSum) => {
	useStore().sum.value = sum;
};

export const saveDealSumList = (options: DealSumList) => {
	useStore().options.value = options;
};

export const readDealSum = () => {
	const { sum } = useStore();
	return sum.value;
};

export const readDealSumList = () => {
	const { options } = useStore();
	return options.value;
};
