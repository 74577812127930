import type { SumData } from '../../core/types';
import { createEventHook } from '~/src/shared/lib';

type SumSelected = (data: SumData) => void;

const HOOK_NAME = 'sum:selected';

declare module '#app/nuxt' {
	interface RuntimeNuxtHooks {
		[HOOK_NAME]: SumSelected;
	}
}

const {
	useOnEvent: useSubscribeSumSelected,
	useTriggerEvent: useTriggerSumSelected,
} = createEventHook(HOOK_NAME);

export const sumBus = {
	useSubscribeSumSelected,
	useTriggerSumSelected,
};
