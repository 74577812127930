import { default as aboutMPFIpE511zMeta } from "/frontend/pages/about.vue?macro=true";
import { default as accounting0m3yz2pV2kMeta } from "/frontend/pages/admin/accounting.vue?macro=true";
import { default as betsTreeJXLOZOAFZtMeta } from "/frontend/pages/admin/betsTree.vue?macro=true";
import { default as educationfDsYCRJfYsMeta } from "/frontend/pages/admin/education.vue?macro=true";
import { default as faqjaKpdcSdaKMeta } from "/frontend/pages/admin/partners/faq.vue?macro=true";
import { default as indexqSC2ri5H4SMeta } from "/frontend/pages/admin/partners/index.vue?macro=true";
import { default as newsgAc89pwKiEMeta } from "/frontend/pages/admin/partners/news.vue?macro=true";
import { default as paymentsmkIsrbfZxRMeta } from "/frontend/pages/admin/partners/payments.vue?macro=true";
import { default as promoEDip0PWCTqMeta } from "/frontend/pages/admin/partners/promo.vue?macro=true";
import { default as statisticsm0Qsj5X0DeMeta } from "/frontend/pages/admin/partners/statistics.vue?macro=true";
import { default as supportnKVkhhBVcHMeta } from "/frontend/pages/admin/partners/support.vue?macro=true";
import { default as tournamentsewFuNnr4g8Meta } from "/frontend/pages/admin/partners/tournaments.vue?macro=true";
import { default as promoCodesIs7t047DukMeta } from "/frontend/pages/admin/promoCodes.vue?macro=true";
import { default as promotionszzCIKNuNhXMeta } from "/frontend/pages/admin/promotions.vue?macro=true";
import { default as statisticsjQg1jhRU2HMeta } from "/frontend/pages/admin/statistics.vue?macro=true";
import { default as support0GATRp6K3kMeta } from "/frontend/pages/admin/support.vue?macro=true";
import { default as tournamentsNuCa0ZNlCwMeta } from "/frontend/pages/admin/tournaments.vue?macro=true";
import { default as usersKpDAUYgmr6Meta } from "/frontend/pages/admin/users.vue?macro=true";
import { default as verificationbolc1Ix965Meta } from "/frontend/pages/admin/verification.vue?macro=true";
import { default as adminUKXKIZCt6kMeta } from "/frontend/pages/admin.vue?macro=true";
import { default as contactsVX9L03wWzAMeta } from "/frontend/pages/contacts.vue?macro=true";
import { default as documentsxY6dxfn9neMeta } from "/frontend/pages/documents.vue?macro=true";
import { default as educationOPZ2bAaRXpMeta } from "/frontend/pages/education.vue?macro=true";
import { default as indexzh70oVUhJfMeta } from "/frontend/pages/index.vue?macro=true";
import { default as login8M9opu3hOuMeta } from "/frontend/pages/login.vue?macro=true";
import { default as contacts8XZrr4NgmlMeta } from "/frontend/pages/partners/contacts.vue?macro=true";
import { default as faqhreuDEZDWtMeta } from "/frontend/pages/partners/faq.vue?macro=true";
import { default as _91id_93vwByx7CFIwMeta } from "/frontend/pages/partners/news/[id].vue?macro=true";
import { default as indexsbRm1xatVjMeta } from "/frontend/pages/partners/news/index.vue?macro=true";
import { default as newsC0dmUxeozcMeta } from "/frontend/pages/partners/news.vue?macro=true";
import { default as paymentsqJMzzMg5rVMeta } from "/frontend/pages/partners/payments.vue?macro=true";
import { default as promor8rQTpDYm3Meta } from "/frontend/pages/partners/promo.vue?macro=true";
import { default as referalsYgodszGZgWMeta } from "/frontend/pages/partners/referals.vue?macro=true";
import { default as indexYk8qxrRSDnMeta } from "/frontend/pages/partners/statistics/index.vue?macro=true";
import { default as _91id_930U4tQjkis4Meta } from "/frontend/pages/partners/statistics/traders/[id].vue?macro=true";
import { default as supportOnjlw69h4MMeta } from "/frontend/pages/partners/support.vue?macro=true";
import { default as tariffs7YhPTzLs1DMeta } from "/frontend/pages/partners/tariffs.vue?macro=true";
import { default as tournamentsr5BF26fY2gMeta } from "/frontend/pages/partners/tournaments.vue?macro=true";
import { default as partnersNbmHKpR9nSMeta } from "/frontend/pages/partners.vue?macro=true";
import { default as registrationw1SReHAx4FMeta } from "/frontend/pages/registration.vue?macro=true";
import { default as bonusest3N6r4503rMeta } from "/frontend/pages/terminal/bonuses.vue?macro=true";
import { default as dealsabK0LCSviNMeta } from "/frontend/pages/terminal/deals.vue?macro=true";
import { default as educationYcusZXxiPRMeta } from "/frontend/pages/terminal/education.vue?macro=true";
import { default as finances6jit96ioj1Meta } from "/frontend/pages/terminal/finances.vue?macro=true";
import { default as helppwvv9frcpCMeta } from "/frontend/pages/terminal/help.vue?macro=true";
import { default as tournamentsB4GuMcCaA2Meta } from "/frontend/pages/terminal/tournaments.vue?macro=true";
import { default as terminalAHRo9qZt1qMeta } from "/frontend/pages/terminal.vue?macro=true";
export default [
  {
    name: "about",
    path: "/about",
    component: () => import("/frontend/pages/about.vue").then(m => m.default || m)
  },
  {
    name: "admin",
    path: "/admin",
    meta: adminUKXKIZCt6kMeta || {},
    component: () => import("/frontend/pages/admin.vue").then(m => m.default || m),
    children: [
  {
    name: "admin-accounting",
    path: "accounting",
    component: () => import("/frontend/pages/admin/accounting.vue").then(m => m.default || m)
  },
  {
    name: "admin-betsTree",
    path: "betsTree",
    component: () => import("/frontend/pages/admin/betsTree.vue").then(m => m.default || m)
  },
  {
    name: "admin-education",
    path: "education",
    component: () => import("/frontend/pages/admin/education.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-faq",
    path: "partners/faq",
    component: () => import("/frontend/pages/admin/partners/faq.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners",
    path: "partners",
    component: () => import("/frontend/pages/admin/partners/index.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-news",
    path: "partners/news",
    component: () => import("/frontend/pages/admin/partners/news.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-payments",
    path: "partners/payments",
    component: () => import("/frontend/pages/admin/partners/payments.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-promo",
    path: "partners/promo",
    component: () => import("/frontend/pages/admin/partners/promo.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-statistics",
    path: "partners/statistics",
    component: () => import("/frontend/pages/admin/partners/statistics.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-support",
    path: "partners/support",
    component: () => import("/frontend/pages/admin/partners/support.vue").then(m => m.default || m)
  },
  {
    name: "admin-partners-tournaments",
    path: "partners/tournaments",
    component: () => import("/frontend/pages/admin/partners/tournaments.vue").then(m => m.default || m)
  },
  {
    name: "admin-promoCodes",
    path: "promoCodes",
    component: () => import("/frontend/pages/admin/promoCodes.vue").then(m => m.default || m)
  },
  {
    name: "admin-promotions",
    path: "promotions",
    component: () => import("/frontend/pages/admin/promotions.vue").then(m => m.default || m)
  },
  {
    name: "admin-statistics",
    path: "statistics",
    component: () => import("/frontend/pages/admin/statistics.vue").then(m => m.default || m)
  },
  {
    name: "admin-support",
    path: "support",
    component: () => import("/frontend/pages/admin/support.vue").then(m => m.default || m)
  },
  {
    name: "admin-tournaments",
    path: "tournaments",
    component: () => import("/frontend/pages/admin/tournaments.vue").then(m => m.default || m)
  },
  {
    name: "admin-users",
    path: "users",
    component: () => import("/frontend/pages/admin/users.vue").then(m => m.default || m)
  },
  {
    name: "admin-verification",
    path: "verification",
    component: () => import("/frontend/pages/admin/verification.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "contacts",
    path: "/contacts",
    component: () => import("/frontend/pages/contacts.vue").then(m => m.default || m)
  },
  {
    name: "documents",
    path: "/documents",
    component: () => import("/frontend/pages/documents.vue").then(m => m.default || m)
  },
  {
    name: "education",
    path: "/education",
    component: () => import("/frontend/pages/education.vue").then(m => m.default || m)
  },
  {
    name: "index",
    path: "/",
    component: () => import("/frontend/pages/index.vue").then(m => m.default || m)
  },
  {
    name: "login",
    path: "/login",
    meta: login8M9opu3hOuMeta || {},
    component: () => import("/frontend/pages/login.vue").then(m => m.default || m)
  },
  {
    name: "partners",
    path: "/partners",
    meta: partnersNbmHKpR9nSMeta || {},
    component: () => import("/frontend/pages/partners.vue").then(m => m.default || m),
    children: [
  {
    name: "partners-contacts",
    path: "contacts",
    component: () => import("/frontend/pages/partners/contacts.vue").then(m => m.default || m)
  },
  {
    name: "partners-faq",
    path: "faq",
    component: () => import("/frontend/pages/partners/faq.vue").then(m => m.default || m)
  },
  {
    name: newsC0dmUxeozcMeta?.name,
    path: "news",
    component: () => import("/frontend/pages/partners/news.vue").then(m => m.default || m),
    children: [
  {
    name: "partners-news-id",
    path: ":id()",
    component: () => import("/frontend/pages/partners/news/[id].vue").then(m => m.default || m)
  },
  {
    name: "partners-news",
    path: "",
    component: () => import("/frontend/pages/partners/news/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "partners-payments",
    path: "payments",
    component: () => import("/frontend/pages/partners/payments.vue").then(m => m.default || m)
  },
  {
    name: "partners-promo",
    path: "promo",
    component: () => import("/frontend/pages/partners/promo.vue").then(m => m.default || m)
  },
  {
    name: "partners-referals",
    path: "referals",
    component: () => import("/frontend/pages/partners/referals.vue").then(m => m.default || m)
  },
  {
    name: "partners-statistics",
    path: "statistics",
    component: () => import("/frontend/pages/partners/statistics/index.vue").then(m => m.default || m)
  },
  {
    name: "partners-statistics-traders-id",
    path: "statistics/traders/:id()",
    component: () => import("/frontend/pages/partners/statistics/traders/[id].vue").then(m => m.default || m)
  },
  {
    name: "partners-support",
    path: "support",
    component: () => import("/frontend/pages/partners/support.vue").then(m => m.default || m)
  },
  {
    name: "partners-tariffs",
    path: "tariffs",
    component: () => import("/frontend/pages/partners/tariffs.vue").then(m => m.default || m)
  },
  {
    name: "partners-tournaments",
    path: "tournaments",
    component: () => import("/frontend/pages/partners/tournaments.vue").then(m => m.default || m)
  }
]
  },
  {
    name: "registration",
    path: "/registration",
    meta: registrationw1SReHAx4FMeta || {},
    component: () => import("/frontend/pages/registration.vue").then(m => m.default || m)
  },
  {
    name: "terminal",
    path: "/terminal",
    meta: terminalAHRo9qZt1qMeta || {},
    component: () => import("/frontend/pages/terminal.vue").then(m => m.default || m),
    children: [
  {
    name: "terminal-bonuses",
    path: "bonuses",
    component: () => import("/frontend/pages/terminal/bonuses.vue").then(m => m.default || m)
  },
  {
    name: "terminal-deals",
    path: "deals",
    component: () => import("/frontend/pages/terminal/deals.vue").then(m => m.default || m)
  },
  {
    name: "terminal-education",
    path: "education",
    component: () => import("/frontend/pages/terminal/education.vue").then(m => m.default || m)
  },
  {
    name: "terminal-finances",
    path: "finances",
    component: () => import("/frontend/pages/terminal/finances.vue").then(m => m.default || m)
  },
  {
    name: "terminal-help",
    path: "help",
    component: () => import("/frontend/pages/terminal/help.vue").then(m => m.default || m)
  },
  {
    name: "terminal-tournaments",
    path: "tournaments",
    component: () => import("/frontend/pages/terminal/tournaments.vue").then(m => m.default || m)
  }
]
  }
]