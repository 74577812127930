import type { RouteLocationNamedRaw, RouteLocationPathRaw } from '#vue-router';

const routes = {
	index: '/',
	education: '/education/',
	documents: '/documents/',
	about: '/about/',
	contacts: '/contacts/',
	login: '/login',
	registration: '/registration',
	terminal: '/terminal',
	terminalDeals: '/terminal/deals',
	terminalFinances: '/terminal/finances',
	terminalTournaments: '/terminal/tournaments',
	terminalEducation: '/terminal/education',
	terminalBonuses: '/terminal/bonuses',
	terminalHelp: '/terminal/help',
	admin: '/admin',
	adminUsers: '/admin/users',
	adminStatistics: '/admin/statistics',
	adminVerification: '/admin/verification',
	adminBetsTree: '/admin/betsTree',
	adminAccounting: '/admin/accounting',
	adminTournaments: '/admin/tournaments',
	adminPromoCodes: '/admin/promoCodes',
	adminSupport: '/admin/support',
	adminEducation: '/admin/education',
	adminPromotions: '/admin/promotions',
	adminPartners: '/admin/partners',
	adminPartnersStatistics: '/admin/partners/statistics',
	adminPartnersPayments: '/admin/partners/payments',
	adminPartnersSupport: '/admin/partners/support',
	adminPartnersTournaments: '/admin/partners/tournaments',
	adminPartnersPromo: '/admin/partners/promo',
	adminPartnersNews: '/admin/partners/news',
	adminPartnersFaq: '/admin/partners/faq',
	partners: '/partners',
	partnersStatistics: '/partners/statistics',
	partnersPromo: '/partners/promo',
	partnersReferals: '/partners/referals',
	partnersPayments: '/partners/payments',
	partnersTournaments: '/partners/tournaments',
	partnersNews: '/partners/news',
	partnersTariffs: '/partners/tariffs',
	partnersSupport: '/partners/support',
	partnersFaq: '/partners/faq',
	partnersContacts: '/partners/contacts',
} as const;

type LocaleRoutes = Record<keyof typeof routes, string>;

export const useRoutes = () => {
	const localePath = useLocalePath();

	return computed(
		() =>
			Object.fromEntries(
				Object.entries(routes).map(([key, value]) => [key, localePath(value)]),
			) as LocaleRoutes,
	);
};

const createUseOpenPage = (routeKey: keyof typeof routes) => () => {
	const routes = useRoutes();
	const router = useRouter();

	const link = computed(() => routes.value[routeKey]);

  type Params = RouteLocationPathRaw | RouteLocationNamedRaw;

  return (params: Omit<Params, 'path'> = {}) =>
  	router.push({
  		...params,
  		path: link.value,
  	});
};

const createUsePageLink = (routeKey: keyof typeof routes) => () => {
	const routes = useRoutes();

	return computed(() => routes.value[routeKey]);
};

// const createUsePageDetailLink = (routeKey: keyof typeof routes) => {
//   const useLink = createUsePageLink(routeKey);

//   return (id: string) => {
//     const link = useLink();

//     return computed(() => `${link.value}/${id}`);
//   };
// };

export const useOpenIndexPage = createUseOpenPage('index');
export const useOpenRegistrationPage = createUseOpenPage('registration');
export const useOpenTerminalPage = createUseOpenPage('terminal');
export const useOpenAdminUsersPage = createUseOpenPage('adminUsers');
export const useOpenLoginPage = createUseOpenPage('login');

export const useIndexPageLink = createUsePageLink('index');
export const useAdminPageLink = createUsePageLink('admin');
export const usePartnersPageLink = createUsePageLink('partners');
export const useTerminalPageLink = createUsePageLink('terminal');
export const useLoginPageLink = createUsePageLink('login');
export const useRegistrationPageLink = createUsePageLink('registration');
export const useEducationPageLink = createUsePageLink('education');
