import { createGlobalState } from '@vueuse/core';

const useStore = createGlobalState(() => {
	const isModalOpen = ref(false);
	const isBlockAccountModalOpen = ref(false);
	const isEmailEdit = ref(false);
	const isChangePasswordEdit = ref(false);
	const isLoginEdit = ref(false);

	return {
		isModalOpen,
		isBlockAccountModalOpen,
		isEmailEdit,
		isChangePasswordEdit,
		isLoginEdit,
	};
});

export const useIsProfileModalOpen = () => {
	const { isModalOpen } = useStore();
	return computed(() => isModalOpen.value);
};

export const saveIsModalOpen = (isOpen: boolean) =>
	(useStore().isModalOpen.value = isOpen);

export const useIsBlockAccountModalOpen = () => {
	const { isBlockAccountModalOpen } = useStore();
	return computed(() => isBlockAccountModalOpen.value);
};

export const saveIsBlockAccountModalOpen = (isOpen: boolean) =>
	(useStore().isBlockAccountModalOpen.value = isOpen);

export const useIsEmailEdit = () => {
	const { isEmailEdit } = useStore();
	return computed(() => isEmailEdit.value);
};

export const saveIsEmailEdit = (isEdit: boolean) =>
	(useStore().isEmailEdit.value = isEdit);

export const useIsChangePasswordEdit = () => {
	const { isChangePasswordEdit } = useStore();
	return computed(() => isChangePasswordEdit.value);
};

export const saveIsChangePasswordEdit = (isEdit: boolean) =>
	(useStore().isChangePasswordEdit.value = isEdit);

export const useIsLoginEdit = () => {
	const { isLoginEdit } = useStore();
	return computed(() => isLoginEdit.value);
};

export const saveIsLoginEdit = (isEdit: boolean) =>
	(useStore().isLoginEdit.value = isEdit);
