import type { UserCurrent } from '../../types';
import { USER_ROLES } from '~/src/shared/lib';

export const isFullVerified = (_user: UserCurrent) => false;
// user.emailVerified &&
// user.userVerified &&
// user.phoneVerified &&
// user.verificationStatus === "verified";
// TODO

export const isVip = (user: UserCurrent) => user.accountType === 'vip';
export const isAdmin = (user: UserCurrent) => user.groupId === USER_ROLES.admin;
export const isPartner = (user: UserCurrent) =>
	user.groupId === USER_ROLES.partner;
export const isTrader = (user: UserCurrent) =>
	user.groupId === USER_ROLES.trader;

export const getBgColor = ({
	colorCode,
}: Pick<UserCurrent, 'colorCode'>): string => {
	const mapper: Record<UserCurrent['colorCode'], string> = {
		1: 'bg-orange-500',
		2: 'bg-primary-500',
		3: 'bg-yellow-500',
		4: 'bg-green-500',
		5: 'bg-teal-500',
		6: 'bg-indigo-500',
		7: 'bg-blue-500',
		8: 'bg-purple-500',
		9: 'bg-pink-500',
	};

	return mapper[colorCode];
};
