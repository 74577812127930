<script setup lang="ts">
import { BaseButton } from '@legacy-studio/library';
import type { TariffType } from '../types';
import { TariffsBadge } from '.';
import { useUserAccountType } from '@/src/features/user';
import { UiDarkBBButton } from '~/src/shared/ui';

const props = defineProps<{
	type: TariffType;
}>();

defineEmits<{
	(event: 'increase', value: TariffType): void;
}>();

const current = useUserAccountType();

interface Tariff {
	type: TariffType;
	price: number;
}

// TODO

const tariffs: Record<TariffType, Tariff> = {
	free: {
		type: 'free',
		price: 0,
	},
	standart: {
		type: 'standart',
		price: 10,
	},
	gold: {
		type: 'gold',
		price: 500,
	},
	premium: {
		type: 'premium',
		price: 1300,
	},
	vip: {
		type: 'vip',
		price: 3000,
	},
};

const getTariffPrice = (type: TariffType) => tariffs[type].price;

const price = computed(() => {
	return `$ ${getTariffPrice(props.type)}`;
});

const infoList = computed(() => {
	if (props.type === 'free') {
		return ['recources', 'tournaments'];
	}
	else if (props.type === 'standart') {
		return ['limit', 'requests', 'recources', 'tournaments'];
	}
	else if (props.type === 'gold') {
		return ['limit', 'requests', 'income', 'recources', 'tournaments'];
	}
	else if (props.type === 'premium') {
		return ['limit', 'requests', 'income', 'recources', 'tournaments'];
	}
	else if (props.type === 'vip') {
		return ['limit', 'requests', 'income', 'recources', 'tournaments'];
	}
	else {
		return [];
	}
});

const onClickIncrease = () => {
	Partners('increase', props.type);
};
</script>

<template>
	<div
		class="flex h-full w-full max-w-[448px] flex-col rounded-lg border border-gray-600 bg-gray-700 p-8 shadow-2xl xl:px-4"
	>
		<TariffsBadge
			:type="type"
			class="mx-auto mb-4"
		/>

		<p class="mb-6 text-center text-xl font-extrabold text-white">
			{{ price }}
		</p>

		<UiDarkBBButton
			v-if="type !== current"
			:button-text="$t('sections.accounts.btn.increase')"
			class="mb-6 md:mb-[26px]"
			@click="onClickIncrease"
		/>

		<BaseButton
			v-else
			color="alternative-dark"
			disabled
			:button-text="$t('sections.accounts.btn.current')"
			class="mb-6 md:mb-[26px]"
			outline
		/>

		<div class="flex flex-col gap-4 text-left">
			<div
				v-for="key in infoList"
				:key="key"
				class="flex min-h-[50px] flex-col gap-[2px] text-sm font-normal text-gray-500"
			>
				<span>{{ $t(`sections.accounts.list.${key}`) }}</span>
				<span v-html="$t(`sections.accounts.values.${type}.${key}`)" />
			</div>
		</div>
	</div>
</template>
