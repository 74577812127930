import { createGlobalState } from '@vueuse/core';
import type {
	Chart,
	ChartInterval,
	ChartType,
	ChartSeries,
	ChartDrawing,
	ChartIndicatior,
	ChartCandleSize,
	ChartRateItem,
} from '../../types';
import { CHART_CANDLE_SIZE, CHART_INTERVAL, CHART_TYPE } from '../../constants';
import type { AssetId, AssetItem, AssetsList } from '~/src/features/assets';
import type { IChartingLibraryWidget, IPositionLineAdapter } from '~/public/charting_library/charting_library';

const useStore = createGlobalState(() => {
	const assets = ref<AssetsList>([]);
	const activeAsset = ref<null | AssetItem>(null);

	const widget = ref<null | IChartingLibraryWidget>(null);
	const chart = ref<null | Chart>(null);
	const series = ref<null | ChartSeries>(null);

	const type = ref<ChartType>(CHART_TYPE.line);
	const interval = ref<ChartInterval>(CHART_INTERVAL['1min']);
	const candleSize = ref<ChartCandleSize>(CHART_CANDLE_SIZE['1min']);
	const drawingItems = ref<ChartDrawing[]>([]);
	const indicatorItems = ref<ChartIndicatior[]>([]);
	const chartRatesData = ref<Array<ChartRateItem>>([]);
	const timeLeftPeriodRange = ref<TimeStamp | null>(null);

	const socketRatesData = ref<Record<string, ChartRateItem>>({});

	const chartShapes = ref<({
		shapeId: EntityId;
		assetId: AssetId;
		line?: undefined;
	} | {
		shapeId?: undefined;
		line: IPositionLineAdapter;
		assetId: AssetId;
	})[]>([]);

	return {
		assets,
		activeAsset,

		widget,
		chart,
		series,
		chartRatesData,
		timeLeftPeriodRange,
		chartShapes,

		type,
		interval,
		candleSize,
		drawingItems,
		indicatorItems,

		socketRatesData,
	};
});

// Assets
export const useAssets = () => {
	const { assets } = useStore();
	return computed(() => assets.value);
};
export const saveAssets = (assets: AssetsList) =>
	(useStore().assets.value = assets);
export const readAssets = () => useStore().assets.value;

export const useActiveAsset = () => {
	const { activeAsset } = useStore();
	return computed(() => activeAsset.value);
};
export const saveActiveAsset = (asset: AssetItem) =>
	(useStore().activeAsset.value = asset);
export const readActiveAsset = () => useStore().activeAsset.value;

// Widget
export const useWidget = () => {
	const { widget } = useStore();
	return computed(() => widget.value);
};
export const saveWidget = (widget: IChartingLibraryWidget) =>
	(useStore().widget.value = widget);
export const readWidget = () => useStore().widget.value;

// Chart general
export const useChart = () => {
	const { chart } = useStore();
	return computed(() => chart.value);
};
export const saveChart = (chart: Chart) => (useStore().chart.value = chart);
export const readChart = () => useStore().chart.value;

export const useChartSeries = () => {
	const { series } = useStore();
	return computed(() => series.value);
};
export const saveChartSeries = (series: ChartSeries) =>
	(useStore().series.value = series);
export const readChartSeries = () => useStore().series.value;

export const useChartRatesData = () => {
	const { chartRatesData: ratesData } = useStore();
	return computed(() => ratesData.value);
};

export const saveChartRatesData = (data: ChartRateItem[]) =>
	(useStore().chartRatesData.value = data);

export const readChartRatesData = () => useStore().chartRatesData.value;

export const useTimeLeftPeriodRange = () => {
	const { timeLeftPeriodRange } = useStore();
	return computed(() => timeLeftPeriodRange.value);
};

export const saveTimeLeftPeriodRange = (time: TimeStamp) =>
	(useStore().timeLeftPeriodRange.value = time);

export const readTimeLeftPeriodRange = () =>
	useStore().timeLeftPeriodRange.value;

// Chart properties
export const useChartType = () => {
	const { type } = useStore();
	return computed(() => type.value);
};
export const saveChartType = (type: ChartType) =>
	(useStore().type.value = type);
export const readChartType = () => useStore().type.value;

export const useChartInterval = () => {
	const { interval } = useStore();
	return computed(() => interval.value);
};
export const saveChartInterval = (interval: ChartInterval) =>
	(useStore().interval.value = interval);
export const readChartInterval = () => useStore().interval.value;

export const useChartCandleSize = () => {
	const { candleSize } = useStore();
	return computed(() => candleSize.value);
};

export const saveChartCandleSize = (candleSize: ChartCandleSize) =>
	(useStore().candleSize.value = candleSize);

export const readChartCandleSize = () => useStore().candleSize.value;

export const useChartDrawingItems = () => {
	const { drawingItems } = useStore();
	return computed(() => drawingItems.value);
};

export const saveChartDrawingItems = (items: ChartDrawing[]) =>
	(useStore().drawingItems.value = items);

export const readChartDrawingItems = () => useStore().drawingItems.value;

export const useChartIndicatorItems = () => {
	const { indicatorItems } = useStore();

	return computed(() => indicatorItems.value);
};

export const saveChartIndicatorItems = (items: ChartIndicatior[]) =>
	(useStore().indicatorItems.value = items);

export const readChartIndicatorItems = () => useStore().indicatorItems.value;

export const useChartShapes = () => {
	const { chartShapes } = useStore();
	return computed(() => chartShapes.value);
};

export const saveChartShapes = (shapes: { id: EntityId; assetId: AssetId }[]) =>
	(useStore().chartShapes.value = shapes);

export const readChartShapes = () => useStore().chartShapes.value;

export const useChartProperties = () => {
	const type = useChartType();
	const interval = useChartInterval();
	const candleSize = useChartCandleSize();
	const drawingItems = useChartDrawingItems();
	const indicatorItems = useChartIndicatorItems();

	return computed(() => ({
		type: type.value,
		interval: interval.value,
		candleSize: candleSize.value,
		drawingItems: drawingItems.value,
		indicatorItems: indicatorItems.value,
	}));
};

export const useSocketRatesData = () => {
	const { socketRatesData } = useStore();
	return computed(() => socketRatesData.value);
};

export const saveSocketRatesData = (data: Record<string, ChartRateItem>) =>
	(useStore().socketRatesData.value = data);

export const readSocketRatesData = () => useStore().socketRatesData.value;
