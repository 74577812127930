import type { DealSum, DealSumList } from '../../types';

const FIRST_VALUE = 1;
const PROGRESSION_DIFFERENCE = 50;
const PROGRESSION_LENGTH = 21;
const MAX_DEAL_SUM = 1000;

export const generateDealSumList = () => {
	return [
		FIRST_VALUE,
		...new Array(PROGRESSION_LENGTH)
			.fill(null)
			.map((_, idx) => idx * PROGRESSION_DIFFERENCE)
			.slice(1),
	];
};

export const getNextDealSum = (current: DealSum, list: DealSumList) => {
	const idx = list.findIndex(item => item > current);

	if (idx !== -1 && idx + 1 < list.length) {
		return list[idx];
	}
	else {
		return list[list.length - 1];
	}
};

export const getPrevDealSum = (current: DealSum, list: DealSumList) => {
	const reversed = [...list].reverse();
	const idx = reversed.findIndex(item => item < current);

	if (idx >= 0) {
		return reversed[idx];
	}
	else {
		return reversed[reversed.length - 1];
	}
};

export const createDealSum = (sumCandidate: unknown): number => {
	const sum = +(String(sumCandidate).match(/\d+/g)?.join('') || '0');
	if (sum > MAX_DEAL_SUM) {
		return MAX_DEAL_SUM;
	}
	else {
		return sum;
	}
};
