import { extend } from 'dayjs';
import utc from 'dayjs/plugin/utc';
import type { OpenDealParams } from '../types';
import { terminalBus } from '../../infrastructure/bus';
import { useDealSum } from '@/src/features/terminal-deal-sum';
import { useExpirationTime } from '~/src/features/terminal-expiration-time';
import { useActiveInvoice } from '@/src/features/invoices';
import { useActiveAsset } from '~/src/features/terminal';

extend(utc);

export const useCreateDeal = () => {
	const invoice = useActiveInvoice();
	const asset = useActiveAsset();
	const expirationTime = useExpirationTime();
	const sum = useDealSum();
	const triggerTermianlCreateDeal = terminalBus.useTriggerTermianlCreateDeal();

	return (openDealData: OpenDealParams) => {
		const direction = openDealData.direction;

		if (!invoice.value) {
			alert('no invoice id');
			return;
		}

		if (!asset.value) {
			alert('no asset');
			return;
		}

		const data = {
			direction,
			sum: sum.value,
			expirationTime: expirationTime.value.value.toString(),
			invoiceId: invoice.value.id,
			assetId: asset.value.id,
			assetPrice: 100,
		};

		triggerTermianlCreateDeal(data);
	};
};
