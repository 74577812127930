import type { TransactionHistory } from '../../types';
import { useApi } from '~/src/services/network';
import type { OptionsList } from '~/src/shared/kernel';
import type { Response } from '~/src/shared/lib';

export type TransactionsDTO = Response<{
	filters: {
		type: OptionsList;
		status: OptionsList;
	};
	items: {
		id: string;
		user: string;
		invoice: null;
		order: string;
		date: DateString;
		type: string;
		paysystem?: {
			id: string;
			name: string;
			freekassa_id: string;
			currency: string;
			min_sum: null;
			max_sum: null;
			type: string;
		};
		status: {
			id: string;
			name: string;
			code: 'in_process' | 'fail' | 'success';
		};
		sum: number;
		account: null;
		cancellation_reason: null;
	}[];
	count: number;
	count_total: number;
}>;

const getStatusMode = (
	data: TransactionsDTO['result']['items'][0]['status']['code'],
) => {
	if (data === 'fail') {
		return 'progress';
	}
	else if (data === 'in_process') {
		return 'failure';
	}
	else {
		return 'success';
	}
};

export const toDomain = (data: TransactionsDTO): TransactionHistory => ({
	filters: {
		types: data.result.filters.type,
		statuses: data.result.filters.status,
	},
	items: data.result.items.map(item => ({
		date: item.date,
		number: +item.id,
		type: item.type,
		asset: item.paysystem?.name || '',
		status: {
			variant: getStatusMode(item.status.code),
			text: item.status.name,
		},
		sum: item.sum,
	})),
	count: data.result.count,
});

export const useGetList = () => {
	const api = useApi();

	return async (filters: object) =>
		(
			await api('/Transactions/get/', {
				body: filters,
				method: 'POST',
			})
		).mapRight(toDomain);
};
