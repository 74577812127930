import { left, right } from '@sweet-monads/either';
import { useConfig } from '@/src/services/config';
import {
	ERROR_CODES,
	NetworkBadRequestError,
	NetworkInternalServerError,
	NetworkValidationError,
} from '~/src/shared/lib';
import type { ErrorPayload } from '~/src/shared/kernel';
import { TokenService } from '@/src/features/auth';

let apiInstance: ReturnType<typeof $fetch.create>;
export const useApiInstance = () => {
	const config = useConfig();

	if (!apiInstance) {
		apiInstance = $fetch.create({
			baseURL: config.baseApiUrl,
			credentials: 'include',
			retry: false,
		});
	}

	return apiInstance;
};

export const useApi = () => {
	const apiInstance = useApiInstance();

	return async <R, K extends string = string>(
		url: Parameters<typeof apiInstance>[0],
		opts?: Parameters<typeof apiInstance>[1],
	) => {
		try {
			return right(
				await apiInstance<R>(url, {
					...opts,
					headers: {
						...opts?.headers,
						'Content-Type': 'application/json',
						'Authorization': `Bearer ${TokenService.get()}`,
					},
				}),
			);
		}
		catch (error) {
			const maybeError = getKnownError<K>(error);
			if (maybeError) {
				return left(maybeError);
			}

			throw getUnknownError(error);
		}
	};
};

export function getError(error: unknown) {
	return getKnownError(error) || getUnknownError(error);
}

export function getKnownError<T extends string>(e: unknown) {
	if (!(e instanceof Error)) {
		return null;
	}
	else if (
		'statusCode' in e
		&& e.statusCode
		&& typeof e.statusCode === 'number'
	) {
		if (e.statusCode === ERROR_CODES.VALIDATION_ERROR) {
			return new NetworkValidationError<T>(
				e.message,
				(e as unknown as { data: ErrorPayload<T> }).data,
			);
		}
		else if (e.statusCode === ERROR_CODES.BAD_REQUEST) {
			return new NetworkBadRequestError(e.data?.errorMessage || e.data.message);
		}

		return null;
	}

	return null;
}

export function getUnknownError(e: unknown) {
	if (e instanceof NetworkInternalServerError) {
		return e;
	}

	if (e instanceof Error) {
		return new NetworkInternalServerError(e.message);
	}

	return new NetworkInternalServerError('unknown error');
}
